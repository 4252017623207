import React, { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import Layout from "./../components/Layout/Layout";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Spinner from "../components/Spinner";
import { toast } from "react-toastify";
import styles from "./createListing.module.css";
import {
    getStorage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
} from "firebase/storage";
import { db } from "../firebase.config";
import { addDoc, collection, serverTimestamp, updateDoc, getDoc, doc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import SwipeCore, { EffectCoverflow, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

SwipeCore.use([EffectCoverflow, Pagination]);
const CreateListing = () => {
    const [listing, setListing] = useState("");
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        type: "blog",
        name: "",
        body: "",
        images: {},
    });

    const { type, name, body, images } = formData;

    const auth = getAuth();
    const navigate = useNavigate();
    const isMounted = useRef(true);
    
    useEffect(() => {
        const fetchListing = async () => {
          const docRef = doc(db, "listings", params.listingId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            console.log(docSnap.data());
            setFormData(prev => ({...prev, name: docSnap.data()?.name, body: docSnap.data()?.body}));
            setListing(docSnap.data());
            setLoading(false);
          }
        };
        fetchListing();
      }, [params.listingId]);
    
     
    useEffect(() => {
        if (isMounted) {
            onAuthStateChanged(auth, (user) => {
                setFormData({
                    ...formData,
                    useRef: user.uid,
                });
            });
        } else {
            navigate("/signin");
        }

        // eslint-disable-next-line
    }, []);

    if (loading) {
        return <Spinner />;
    }

    //mutate func
    const onChangeName = (e) => { 
    
        setFormData(prev => ({...prev, name: e.target.value}))
    };

    const onChangeBody = (e) => { 
    
        setFormData(prev => ({...prev,  body: e.target.value}))
    };
    const onChangeHandler = (e) => {
        let boolean = null;
        if (e.target.value === "true") {
          boolean = true;
        }
        if (e.target.value === "false") {
          boolean = false;
        }
        //files
        if (e.target.files) {
          setFormData((prevState) => ({
            ...prevState,
            images: e.target.files,
          }));
        }
        //text/booleans/number
        if (!e.target.files) {
          setFormData((prevState) => ({
            ...prevState,
            [e.target.id]: boolean ?? e.target.value,
          }));
        }
      };


    //form submit
    const onSubmit = async (e) => {
        e.preventDefault();
        // console.log(formData);

        if (images > 1) {
            setLoading(false);
            toast.error("Max 1 Images can be selected");
            return;
        }

        //store images to firebase storage
        const storeImage = async (image) => {
            return new Promise((resolve, reject) => {
                const storage = getStorage();
                const fileName = `${auth.currentUser.uid}-${image.name}-${uuidv4()}`;
                const storageRef = ref(storage, "images/" + fileName);
                const uploadTask = uploadBytesResumable(storageRef, image);
                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        const progress =
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log("uplloas is" + progress + "% done");
                        switch (snapshot.state) {
                            case "paused":
                                console.log("upload is paused");
                                break;
                            case "running":
                                console.log("upload is runnning");
                        }
                    },
                    (error) => {
                        reject(error);
                    },
                    //success
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            resolve(downloadURL);
                        });
                    }
                );
            });
        };
        const imgUrls = await Promise.all(
            [...images].map((image) => storeImage(image))
        ).catch(() => {
            setLoading(false);
            toast.error("Images not uploaded");
            return;
        });
        console.log(imgUrls);

        //save form data
        const formDataCopy = {
            ...formData,
            imgUrls,

            timestamp: serverTimestamp(),
        };
        formData.location = body;
        delete formDataCopy.images;
        !formDataCopy.offer && delete formDataCopy.discountedPrice;
        const docRef = await updateDoc(doc(db, "listings", params.listingId), formDataCopy);
        toast.success("Listing Created!");
        setLoading(false);
        navigate(`/category/${formDataCopy.type}/${docRef.id}`);
   };
    return (
        <Layout>
            <div className={styles.container}>
                <div>
                    {/* blog button */}
                    <form onSubmit={onSubmit}>
                        <div className="d-flex flex-row mt-4">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    value="blog"
                                    onChange={() => {}}
                                    defaultChecked
                                    name="type"
                                    id="type"
                                />
                                <label className="form-check-label" htmlFor="blogs">
                                    Edit Blogs
                                </label>
                            </div>
                        </div>
                        {/* name */}
                        <div className={styles.inputcontainer}>
                            <label htmlFor="name" className="form-label">
                                Name
                            </label>
                            <input
                                type="text"
                                className={styles.inputfild}
                                id="name"
                                value={formData.name}
                                onChange={onChangeName}
                                required
                            />
                        </div>

                        {/* body */}
                        <div className="mb-3">
                            <label htmlFor="body">body :</label>
                            <textarea
                                className={styles.inputfild2}
                                placeholder="Enter Your body"
                                id="body"
                                value={formData.body}
                                onChange={onChangeBody}
                                required
                            />
                        </div>

                        {/* files images etc */}
                        <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">
                          select images :
                        </label>
                        <input
                          className={styles.inputfild}
                          type="file"
                          id="images"
                          name="images"
                          onChange={onChangeHandler}
                          max="6"
                          accept=".jpg,.png,.jpeg"
                          multiple
                          required
                        />
                      </div>
                        {/* submit button */}
                        <div className="mb-3">
                            <input
                                disabled={!name || !body }
                                className="btn btn-primary w-100"
                                type="submit"
                                value="Create Listing"
                            />
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    );
};

export default CreateListing;
