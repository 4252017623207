import React from 'react';
import AboutUSFirst from './first';
import Bannerforaboutus from './Bannerforaboutus';
import Slider from "../blog/components/Slider";
import styles from "./aboutus.module.css";

const AboutUS

  = () => {
    
    return (
      <div>
        <Bannerforaboutus />
        <AboutUSFirst />
      

      </div>
    )
  }

export default AboutUS

