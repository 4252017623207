import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BsFillEyeFill } from "react-icons/bs";
import OAuth from "../components/OAuth";
import styles from "./signin.module.css";

const Signin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const { email, password } = formData;
  const navigate = useNavigate();

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  //loginHandler
  const loginHandler = async (e) => {
    e.preventDefault();
    try {
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      if (userCredential.user) {
        toast.success("Login Success");
        navigate("/profile");
      }
    } catch (error) {
      console.log(error);
      toast.error("Invalid Email Or Password");
    }
  };
  return (
    <div className={styles.container}>
      <form className={styles.innerBox} onSubmit={loginHandler}>
        <h4 className={styles.heading}>Sign In</h4>

        <div className={styles.inputcontainer}>
          <label className={styles.heading} htmlFor="exampleInputEmail1">
            Email address
          </label>
          <input
            type="email"
            value={email}
            onChange={onChange}
            className={styles.inputfild}
            id="email"
            aria-describedby="emailHelp"
          />
        </div>
        <div className={styles.inputcontainer}>
          <label className={styles.heading} htmlFor="exampleInputPassword1">
            Password
          </label>
          <input
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={onChange}
            className={styles.inputfild}
            id="password"
          />
        </div>
        <div>
          <span className={styles.heading}>
            show password
            <BsFillEyeFill
              className="text-danger ms-2"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShowPassword((prevState) => !prevState);
              }}
            />
          </span>{" "}
        </div>
        <div className={styles.footer}>
          <button type="submit" className="btn btn-primary">
            Sign in
          </button>
        </div>
      </form>
    </div>
  );
};

export default Signin;
