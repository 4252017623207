import React from "react";
import styles from "./footer.module.css";
import {useNavigate} from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.component}>
    <div>
      <p>
        Copyrights 2021 &copy;{" "}
        <a
          href="https://onewon.in/"
          target="_blank"
          className={styles.onewon}
          rel="noreferrer"
        >
          oneWonLabs
        </a>{" "}
      </p>
      </div>
      {/* <div><button className={styles.contactButton} onClick={() => navigate('/Create')}>
                    Offers{" "}
                  
                  </button></div> */}
      <div className={styles.verticalLine} />
      <div className={styles.monicaTelNo} >
        <p>Monica Dominijanni</p>
        <p>
          <span>
            <b>Partita IVA:</b>
          </span>{" "}
          <span>039180000120</span>
          
        </p>
      </div>
    </div>
  );
};

export default Footer;
