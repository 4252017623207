import React from "react";
import { useNavigate } from "react-router-dom";
import Slider from "../components/Slider";
import Layout from "./../components/Layout/Layout";
import styles from "./homepage.module.css";
import { Link } from "react-router-dom";

const HomePage = () => {
  const navigate = useNavigate();
  const img1 =
    "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cHJvcGVydHl8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60";
  
  return (
    <Layout>
      <div className={styles.container}>
        <Slider />
        <Link to={"/category/blog"} className={styles.no_underlin}>
        <div className={styles.container_cat}>
          <h1>Category</h1>
          <div className={styles.all_blogs}>
            <div className="Imagecontainer">
              <img src={img1} alt="blog" />
           
            </div>
          </div>
          
        </div></Link>
      </div>
    </Layout>
  );
};

export default HomePage;
