import React from "react";

const Footer = () => {
  return (
    <div className="d-flex align-items-center justify-content-center bg-dark text-light p-4">
     
    </div>
  );
};

export default Footer;
