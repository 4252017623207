import React, { useState } from "react";
import styles from "./about-us.module.css";
import { IoArrowForwardOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";
import Slider from "../../blog/components/Slider";




const AboutUSFirst = () => {
  const [searchParams] = useSearchParams();
  searchParams.get("token")
  console.log(searchParams.get("token"))
  const token = searchParams.get("token")
  const navigate = useNavigate();

  const [isComponentVisible, setIsComponentVisible] = useState(false);
  const toggleBottomComponent = () => { setIsComponentVisible(!isComponentVisible) }

  return (
    <div>

      <div className={styles.component} id="about">

        <div className={styles.introComponent}>

          <h2>
            Individuare le agevolazioni idonee per cofinanziare i progetti è la
            leva che permette di sostenere e migliorare la liquidità aziendale
          </h2>
          <div className={styles.underline} />
          <div className={styles.description}>
            <p>Un finanziamento agevolato o un contributo sono misure chiave per le imprese che vogliono sviluppare un vantaggio competitivo nel mercato italiano.
              <br /><br />Abbiamo trovato un vero e proprio Alleato che vanta una stretta Partnership con i maggiori operatori del settore creditizio per poter finanziare i tuoi progetti con lo strumento più adatto al tuo business.
              <br /><br />Ciò consente di avere la possibilità di essere seguiti a 360 gradi: dall’accesso alla finanza agevolata regionale e nazionale, a finanziamenti e linee di credito Medio Credito Centrale (MCC).
              <br /><br />FONDI STRUTTURALI REGIONALI:
              <br />-  POR FESR (Infrastrutture/Ambiente/Ricerca/PMI Start up
              <br />-  POR FSE (Formazione e Aggiornamento)
              <br />-  POR FEASR (Agricoltura)
              <br /><br />FONDI NAZIONALI:
              <br />-  MIBACT (Cultura e Ambiente)
              <br />-  MIT (Trasporti)
              <br />-  MISE (Sviluppo Economico Incentivi e Start up Digitalizzazione Brevetti e Marchi)
              <br />-  MIUR (Ricerca e Sviluppo)
              <br /><br />SERVIZI FINANZA AGEVOLATA E FINANZIAMENTI:
              <br />-  Studio fattibilità
              <br />-  Raccolta documentazione<br />-  Interazione con la banca (se necessario)
              <br />-  Comunicazione con Enti
              <br />-  Rendicontazione
              <br /><br />SERVIZI PROCESSI DI FINANZIAMENTO E CONSULENZA DEDICATA:
              <br />-  Bilancio d’Esercizio
              <br />-  Business Plan
              <br />-  Destrutturazione Centrale Rischi
              <br />-  GDPR<br />-  Factoring </p>
          </div>
          {
            <div className={styles.offers}>
            <a href="/contact" className={styles.acontactButton} >
              <button className={styles.contactButton}  >
               Contattaci{" "}
                <IoArrowForwardOutline
                  color="#fff"
                  size={18}
                  style={{ marginLeft: 10 }}
                />
              </button></a>
            </div>
          }
        </div>
        {
          isComponentVisible && (

            <div className={styles.slider_container}>
              <Slider className={styles.slider} />
            </div>
          )
        }
      </div>
    </div>
  );
};

export default AboutUSFirst;
