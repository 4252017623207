import React from "react";
// import { Link } from "react-router-dom";
import { IoLogoLinkedin } from "react-icons/io5";
// import { Modal } from "rsuite";
// import Typewriter from 'typewriter-effect';
import styles from "./my-story.module.css";
import { useMedia } from "../../../../helper/usemedia";
const Mystory = () => {
  const isMobile = useMedia(600);

  if (isMobile) {
    return (
      <div className={styles.mobileComponent}>
        <div className={styles.mobileInnerComponent}>
          <div className={styles.mobileImageComponent}>
            <img src="/images/monica.jpg" alt="founder logo" />
          </div>
          <div className={styles.mystoryright}>
            <h1>
              <span>La mia storia…</span>
            </h1>
            <div className={styles.underline} />
            <div  className={styles.descriptionComponent}>
             
          <p>
          ...come andare su una montagna russa!
            <br />
            <br />
            Nasco in Calabria, in un piccolo paesino di collina. Ricordo ancora il primo Olivetti regalato dal papà. Che novità! Penso la mia passione sia nata lì.
            <br />
            <br />
            Esportata al nord alla fine del terzo anno di superiori lasciando alle spalle amici, compagni di banco, professori, infanzia. Ho completato comunque la mia maturità una volta arrivata qui e siccome me la sono cavata egregiamente in questo “trauma adolescenziale”, dopo una piccola pausa meditativa, ho deciso che non sarei andata all’Università come invece i miei genitori si auguravano. La mia sentenza fu “imparerò strada facendo”. E così fu.
            <br />
            <br />
            Mi sono lanciata in un sacco di esperienze lavorative che mi hanno fatto diventare ciò che sono oggi. Durante queste esperieze ho sempre coltivato la mia passione: feci un corso di html e dopo qualche anno di Dreamweaver, Flash e Fireworks.
            <br /> <br />
            Finalmente nel 2008 l’Universo decide che sono pronta e si propone un’opportunità in ambito informatico: iniziavo la mia personale avventura di consulente informatico.
            <br /> <br />
            Dal 2008 ad oggi i cambiamenti nella mia vita sono stati molti. Una storia lunga 15 anni, fatta di alta e bassi, di compromessi ed accettazioni forzate, di rischi più o meno calcolati e necessità di sopravvivenza, di amare sconfitte ma anche di grandi soddisfazioni.
            <br /> <br />
            Ho sempre visto e riconosciuto il mio valore e sono sempre stata utilissima come dipendente ma notavo che spesso il mio valore veniva riconosciuto alla mia dipartita.
            <br /> <br />
            Si sa, le donne sono sempre state un pò frenate nella società. Finalmente iniziamo ad avere il nostro palcoscenico e io bhe...sono sempre quella che, dopo un pò che sbatte la testa, inizia a fare a meno degli ostacoli.
            <br /> <br />
            A settembre 2022 stanca di duri compromessi ho scelto che della crescita professionale in un’azienda per la quale sarei stata un numero non me ne poteva più fregare nulla e quindi mi lanciai nel buio: mi sono detta “so qual’è il mio valore, so che sono mentalmente molto elastica e imparo velocemente quindi i clienti li troverò per strada”.
            <br /> <br />
            Mi reputo un Consulente vecchia scuola: mi siedo con il Cliente, ascolto empaticamente le sue esigenze e utilizzo tutto il mio sapere e quello del mio team per trovare una soluzione. 
            <br /> <br />
            Proprio per poter dare un servizio migliore ho deciso di affiancarmi a collaboratori che possono aiutarci a partecipare a bandi per ottenere contributi utili all’investimento e la crescita delle aziende che ho l’onore di seguire.
            <br /> <br />
            So di poter essere un valido supporto aziendale e un leader che anche molti vecchi colleghi seguirebbero con piacere.
            <br /> <br />
            Al vostro servizio.
            <br /> <br />
            





          </p>
            </div>
            <p className={styles.sign}>Monica</p>
            <a
              href="https://www.linkedin.com/in/monica-dominijanni-4b3a1970"
              target="_blank"
              className={styles.linkedin}
            >
              <IoLogoLinkedin
                color="#274585"
                size={25}
                style={{ marginTop: 10, textAlign: "center" }}
              />
            </a>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.root}>
      <img src="/images/monica.jpg" alt="founder logo" />
      <div className={styles.mystoryright}>
        <h1>
          <span>La mia storia…</span>
        </h1>
        <div className={styles.underline} />
        <div className={styles.descriptionComponent}>
          
        <p>
          ...come andare su una montagna russa!
            <br />
            <br />
            Nasco in Calabria, in un piccolo paesino di collina. Ricordo ancora il primo Olivetti regalato dal papà. Che novità! Penso la mia passione sia nata lì.
            <br />
            <br />
            Esportata al nord alla fine del terzo anno di superiori lasciando alle spalle amici, compagni di banco, professori, infanzia. Ho completato comunque la mia maturità una volta arrivata qui e siccome me la sono cavata egregiamente in questo “trauma adolescenziale”, dopo una piccola pausa meditativa, ho deciso che non sarei andata all’Università come invece i miei genitori si auguravano. La mia sentenza fu “imparerò strada facendo”. E così fu.
            <br />
            <br />
            Mi sono lanciata in un sacco di esperienze lavorative che mi hanno fatto diventare ciò che sono oggi. Durante queste esperieze ho sempre coltivato la mia passione: feci un corso di html e dopo qualche anno di Dreamweaver, Flash e Fireworks.
            <br /> <br />
            Finalmente nel 2008 l’Universo decide che sono pronta e si propone un’opportunità in ambito informatico: iniziavo la mia personale avventura di consulente informatico.
            <br /> <br />
            Dal 2008 ad oggi i cambiamenti nella mia vita sono stati molti. Una storia lunga 15 anni, fatta di alta e bassi, di compromessi ed accettazioni forzate, di rischi più o meno calcolati e necessità di sopravvivenza, di amare sconfitte ma anche di grandi soddisfazioni.
            <br /> <br />
            Ho sempre visto e riconosciuto il mio valore e sono sempre stata utilissima come dipendente ma notavo che spesso il mio valore veniva riconosciuto alla mia dipartita.
            <br /> <br />
            Si sa, le donne sono sempre state un pò frenate nella società. Finalmente iniziamo ad avere il nostro palcoscenico e io bhe...sono sempre quella che, dopo un pò che sbatte la testa, inizia a fare a meno degli ostacoli.
            <br /> <br />
            A settembre 2022 stanca di duri compromessi ho scelto che della crescita professionale in un’azienda per la quale sarei stata un numero non me ne poteva più fregare nulla e quindi mi lanciai nel buio: mi sono detta “so qual’è il mio valore, so che sono mentalmente molto elastica e imparo velocemente quindi i clienti li troverò per strada”.
            <br /> <br />
            Mi reputo un Consulente vecchia scuola: mi siedo con il Cliente, ascolto empaticamente le sue esigenze e utilizzo tutto il mio sapere e quello del mio team per trovare una soluzione. 
            <br /> <br />
            Proprio per poter dare un servizio migliore ho deciso di affiancarmi a collaboratori che possono aiutarci a partecipare a bandi per ottenere contributi utili all’investimento e la crescita delle aziende che ho l’onore di seguire.
            <br /> <br />
            So di poter essere un valido supporto aziendale e un leader che anche molti vecchi colleghi seguirebbero con piacere.
            <br /> <br />
            Al vostro servizio.
            <br /> <br />
            










          </p>
        </div>
        <p className={styles.sign}>Monica</p>
        <a
          href="https://www.linkedin.com/in/monica-dominijanni-4b3a1970"
          target="_blank"
        >
          <IoLogoLinkedin color="#274585" size={25} style={{ marginTop: 10 }} />
        </a>

      </div>




    </div>
  );
};

export default Mystory;
