import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "./../components/Layout/Layout";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Spinner from "../components/Spinner";
import { toast } from "react-toastify";
import styles from "./CreateTypeWords.module.css";

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { db } from "../firebase.config";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";

const CreateTypeWords = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    eng: "",
    ita: "",
  });

  const { type, eng, ita } = formData;

  const auth = getAuth();
  const navigate = useNavigate();
  const isMounted = useRef(true);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          useRef: user.uid,
        }));
      } else {
        navigate("/signin");
      }
    });

    return () => (isMounted.current = false);
  }, []);

  if (loading) {
    return <Spinner />;
  }

  const onChangeHandler = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formDataCopy = {
      ...formData,
      timestamp: serverTimestamp(),
    };

    const docRef = await addDoc(collection(db, "typeWords"), formDataCopy);
    toast.success("typeWords Created!");
    setLoading(false);
    navigate(`/category/${docRef.id}`);
  };

  return (
    <Layout>
      <div className={styles.container}>
        <div>
          <form onSubmit={onSubmit}>
            <div className="d-flex flex-row mt-4">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  value="blog"
                  onChange={onChangeHandler}
                  defaultChecked
                  name="type"
                  id="type"
                />
                <label className="form-check-label" htmlFor="blogs">
                  Type Words
                </label>
              </div>
            </div>
            <div className={styles.inputcontainer}>
              <label htmlFor="eng" className="form-label">
                English Words
              </label>
              <input
                type="text"
                className={styles.inputfild}
                id="eng"
                value={eng}
                onChange={onChangeHandler}
                required
              />
            </div>
            <div className={styles.inputcontainer}>
              <label htmlFor="ita" className="form-label">
                Italian Words
              </label>
              <input
                type="text"
                className={styles.inputfild}
                id="ita"
                value={ita}
                onChange={onChangeHandler}
                required
              />
            </div>
            <div className="mb-3">
              <input
                disabled={!eng || !ita}
                className="btn btn-primary w-100"
                type="submit"
                value="Create typeWords"
              />
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default CreateTypeWords;
