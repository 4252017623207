import React, { useEffect, useState } from "react";

import { db } from "../../../blog/firebase.config";
import { toast } from "react-toastify";
import {
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";
import Typewriter from 'typewriter-effect';
import styles from "./TypingEffect.module.css";

const TypingEffect = () => {
  const [typeWords, setTypeWords] = useState([]);
  

  useEffect(() => {
    const fetchTypeWords = async () => {
      try {
        const typeWordsRef = collection(db, "typeWords");
        const q = query(
          typeWordsRef,
          orderBy("timestamp", "desc"),
          limit(10) // Adjust the limit as needed
        );
        const querySnap = await getDocs(q);
        const typeWordsData = querySnap.docs.map((doc) => doc.data()?.eng);
        console.log("data ==>", typeWordsData )
        setTypeWords(typeWordsData);
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch typewords");
      }
    };

    fetchTypeWords();
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.leftdiv} >
        <h2>#</h2>
      </div>

      <div className={styles.rightdiv}>

        <Typewriter
          options={{
            strings: typeWords ,
            autoStart: true,
            loop: true,
          }}
        />
      </div>
    </div>
  );
}

export default TypingEffect;
